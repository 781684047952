* {
  letter-spacing: normal;
  --cyan: #008BD0;
  --disabled-cyan: rgba(0, 139, 208, 0.3);
  
  --mdc-checkbox-selected-focus-icon-color: var(--cyan) !important;
  --mdc-checkbox-selected-hover-icon-color: var(--cyan) !important;
  --mdc-checkbox-selected-icon-color: var(--cyan) !important;
  --mdc-checkbox-selected-pressed-icon-color: var(--cyan) !important;
  --mdc-checkbox-unselected-icon-color: #A2A2A2;
  --mdc-tab-indicator-active-indicator-color: var(--cyan) !important;
  --mat-tab-header-active-focus-indicator-color: var(--cyan) !important;
  --mdc-slider-active-track-color: black !important;
  --mdc-slider-inactive-track-color: black !important;
  --mdc-slider-focus-handle-color: transparent !important;
  --mat-mdc-slider-focus-ripple-color: transparent !important;
  --mat-sidenav-container-divider-color: transparent !important;
  --mdc-slider-inactive-track-height: 2px;
  --mdc-slider-active-track-height: 2px;
  --slider-input-height: 19px;
  --slider-thumb-size: 14px;
  --mdc-snackbar-container-color: white;
  --mdc-snackbar-supporting-text-color: black;
 }

.mat-primary {
  --mdc-text-button-label-text-color: var(--cyan) !important;
  --mat-mdc-button-persistent-ripple-color: var(--cyan) !important;
  --mdc-filled-button-container-color: var(--cyan) !important;
  --mdc-icon-button-icon-color: var(--cyan) !important;
  --mdc-outlined-button-label-text-color: var(--cyan) !important;
  --mdc-outlined-button-outline-color: var(--cyan) !important;
  --mdc-filled-button-disabled-label-text-color: white !important;
  --mdc-filled-button-disabled-container-color: var(--cyan) !important;
}

.avatar-toggle-btn button:hover {
  --mat-mdc-button-persistent-ripple-color: transparent !important;
}

a[mat-button] {
  width: fit-content;

  > span.mat-mdc-button-persistent-ripple.mdc-button__ripple {
    display: none;
  }
}

button, 
.mat-mdc-unelevated-button.mat-mdc-button-base:not(.viewer-tabs) {
  box-sizing: border-box;
  color: #ffffff;
  border: 0px;
  font-size: 14px;
  cursor: pointer;
}

button[mat-flat-button].rounded {
  height: 36px;
  border-radius: 25px !important;
}

button[class~=mat-menu-item] {
  margin: 0px;
  width: 180px !important;
}

button:disabled, a[mat-button]:disabled {
  opacity: 0.3;
  color: white !important;
}

button.vis-dis:disabled {
  opacity: 0.3 !important;
  color: black !important;
}


button:active:not(button[mat-flat-button]) {
  outline:0;
}

button:focus {
  outline:0;
}

button.secondary {
  box-sizing: border-box;
  border: 1px solid #008BD0;
  color: #008BD0;
  background: transparent;
}

button.secondary:disabled {
  opacity: 0.3;
}

button.secondary:active {
  border: 2px solid #006699;
  color: #006699;

  background: white !important;

  outline: 0;
}

button[mat-icon-button].close-btn {
  margin: 0 15px;
  margin-bottom: -7px;
}

.mat-mdc-raised-button.mat-mdc-button-base, 
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 50px;
}

mat-mdc-form-field {
  width: 100%;
  outline: none;
  border: none;
}


mat-mdc-form-field.disabled div.mat-mdc-form-field-outline {
  background: #E9E8E9;
}



mat-mdc-form-field div.mat-mdc-form-field-outline {
  border: none;
  outline: none;

  border-radius: 5px;
  font-size: 16px;

  background: #F7F7F7;
}

mat-mdc-form-field input {
  font-size: 16px;
}


.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline {
  color: transparent;
}


.error-text.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline {
  color: #FF647C;
}


mat-option {
  height: 50px !important;
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
}

.mat-radio-outer-circle {
  border-color: #E9E8E9;
}

.message-box {
  background: #00C48C;
}

.mat-step-header .mat-step-icon-selected {
  background-color: #008BD0;
}

.mat-step-header .mat-step-icon-state-edit {
  background-color: rgba(0, 0, 0, 0.54);
}

.mat-step-icon-content > span {
  font-family: "Roboto" !important;
}

.intro-dialog > .mat-dialog-container {
  position: relative;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 0px;
}

.intro-dialog .mat-horizontal-content-container {
  padding-bottom: 0px;
}

.mat-step-header .mat-step-label {
  overflow: visible;
}

.error-panel {
  background-color: #FFFFFF;
  border-left: 4px solid #008BD0;
  border-radius: 0px !important;
  color: black;
}

.error-panel vcld-snackbar {
  display: flex;
  align-items: center;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

[mat-dialog-title] {
  flex-shrink: 1 !important;
}

.vis-dis {
  flex-direction: row-reverse;
}

@media only screen and (min-width: 0px ) and (orientation:portrait) {
  .mat-step-header .mat-step-label {
    display: none;
  }
  .intro-dialog > .mat-dialog-container {
    padding-top: 0px;
  }
}

@media only screen and (min-width: 601px) {
  .intro-dialog .mat-horizontal-content-container {
    padding-right: 48px;
    padding-left: 48px;
  }

  .intro-dialog > .mat-dialog-container {
    padding-top: 48px;
  }

  .intro-dialog .mat-horizontal-stepper-header-container {
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .mat-step-header .mat-step-label {
    overflow: hidden;
  }
}

@media only screen and (min-width: 640px) and (max-width: 979px) and (orientation:landscape) {
  .mat-step-header .mat-step-label {
    display: none;
  }
  .intro-dialog > .mat-dialog-container {
    padding-top: 0px !important;

  }

  .intro-dialog .mat-horizontal-stepper-header-container {
    padding: 0px 24px;
  }
}

@media only screen and (min-width: 980px) {
  .mat-step-header .mat-step-label {
    display: block !important;
  }

  .intro-dialog .mat-horizontal-content-container {
    padding-right: 48px;
    padding-left: 48px;
  }

  .intro-dialog > .mat-dialog-container {
    padding-top: 48px;
  }

  .intro-dialog .mat-horizontal-stepper-header-container {
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

