.icon {
    background-repeat: no-repeat !important;
    display: inline-block;
    vertical-align: top;
    fill: currentColor;
    height: 24px;
    width: 48px;
    justify-content: center;
}
.visucloud-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
}

.filter-icon {
    @extend .icon;
    height: 48px;
    background-size: 48px 48px !important;
    margin-left: calc((100% - 48px) / 2);
    margin-top: 1vh;
}

.hamburger {
    background: url("../../assets/icons/icon\ burger.svg");
    width: 24px;
}

.nose-slider {
    background: url("../../assets//icons/vto\ icon\ frame\ height.svg");
    width: 24px;
    height: 28px;
}

.avatar {
    -webkit-mask-image: url("../../assets/icons/icon\ avatar.svg");
    mask-image: url("../../assets/icons/icon\ avatar.svg");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
}

.checkmark {
    background: url("../../assets/icons/checkmark\ big.svg");
}

.frame-adjustment {
    background: url("../../assets/icons/frameadjustments-1.svg");
}

.email {
    background: url("../../assets/icons/icon\ email.svg");
    width: 16px;
}

.favorite {
    background: url("../../assets/icons/icon\ fav\ small.svg");
}

.filter {
    background: url("../../assets/icons/icon\ filter.svg");
    width: 100%;
    height: 100%;
}

.frame {
    background: url("../../assets/icons/icon\ frame.svg");
    width: 32px;
}

.frame-square {
    background: url("../../assets/icons/icon\ frame\ squared.svg");
    width: 32px;
    height: 12px;
}

.heart {
    background: url("../../assets/icons/icon\ heart.svg");
    width: 24px;
}

.cloud {
    background: url("../../assets/icons/cloud.svg");
    width: 27px;
    height: 19px;
}

.sun {
    background: url("../../assets/icons/sun.svg");
    width: 26px;
    height: 26px;
}

.next {
    background: url("../../assets/icons/icon\ next.svg");
    width: 20px;
    height: 14px;
}

.lenses-fav {
    background: url("../../assets/icons/fav-lenses.svg");
    width: 24px;
    background-size: 24px 24px !important;
}

.sun-frame {
    background: url("../../assets/filter/spectacle-type/spectacle-type-sunglass.svg");
}

.square-frame {
    background: url("../../assets/filter/frame-shape/frame-shape-square.svg");
}

.female-frame {
    background: url("../../assets/filter/target/gender_female.svg");
}

.male-frame {
    background: url("../../assets/filter/target/gender_male.svg");
}

.unisex-frame {
    background: url("../../assets/filter/target/gender_unisex.svg");
}

.kids-frame {
    background: url("../../assets/filter/target/gender_kids.svg");
}

.rect-frame {
    background: url("../../assets/filter/frame-shape/frame-shape-rectangle.svg");
}

.optical-frame {
    background: url("../../assets/filter/frame-shape/frame-shape-rectangle.svg");
}

.round-frame {
    background: url("../../assets/filter/frame-shape/frame-shape-round.svg");
}

.pilot-frame {
    background: url("../../assets/filter/frame-shape/frame-shape-pilot.svg");
}

.butterfly-frame {
    background: url("../../assets/filter/frame-shape/frame-shape-butterfly.svg");
}

.panto-frame {
    background: url("../../assets/filter/frame-shape/frame-shape-panto.svg");
}

.fullrim-frame {
    background: url("../../assets/filter/rim-type/rim-type-fullrim.svg");
}
.nylor-frame {
    background: url("../../assets/filter/rim-type/rim-type-nylor.svg");
}
.rimless-frame {
    background: url("../../assets/filter/rim-type/rim-type-rimless.svg");
}

.color-frame {
    background: url("../../assets/filter/color/colorfilter-colorful.svg");
}
.dark-frame {
    background: url("../../assets/filter/color/colorfilter-dark.svg");
}
.light-frame {
    background: url("../../assets/filter/color/colorfilter-light.svg");
}

.elegant-frame {
    background: url("../../assets/filter/lifestyle/lifestyle-elegant.png");
}

.sporty-frame {
    background: url("../../assets/filter/lifestyle/lifestyle-sporty.png");
}

.classic-frame {
    background: url("../../assets/filter/lifestyle/lifestyle-classic.png");
}

.urban-frame {
    background: url("../../assets/filter/lifestyle/lifestyle-urban.png");
}

.stylish-frame {
    background: url("../../assets/filter/lifestyle/lifestyle-stylish.png");
}

.wood-frame {
    background: url("../../assets/filter/material/material-wood.png");
}

.plastic-frame {
    background: url("../../assets/filter/material/material-plastic.png");
}

.metal-frame {
    background: url("../../assets/filter/material/material-metal.png");
}

.titanium-frame {
    background: url("../../assets/filter/material/material-titanium.png");
}

.combination-frame {
    background: url("../../assets/filter/material/material-combination.png");
}

.buffalo-frame {
    background: url("../../assets/filter/material/material-horn.png");
}

.acetate-frame {
    background: url("../../assets/filter/material/material-acetat.png");
}

.carbon-frame {
    background: url("../../assets/filter/material/material-carbon.png");
}

.aluminium-frame {
    background: url("../../assets/filter/material/material-aluminium.png");
}

.steel-frame {
    background: url("../../assets/filter/material/material-stainlesssteel.png");
}

.others-frame {
    background: url("../../assets/filter/material/material-other.png");
}

/* Placeholder lens */

.dura-vision {
    background: url("../../assets/icons/icon_placeholder_dura_vision.svg");
}

.dura-vision-mirror {
    background: url("../../assets/icons/icon_placeholder_dura_vision_mirror.svg");
}

.tints-adaptive {
    background: url("../../assets/icons/icon_placeholder_tints_adaptive.svg");
}

.tints-solid {
    background: url("../../assets/icons/icon_placeholder_tints_solid.svg");
}

/* Real Colors */

.coating-blue {
    background: url("../../assets/icons/coatings_blue.svg");
}

.coating-strongblue {
    background: url("../../assets/icons/coatings_strongblue.svg");
}

.coating-bronze {
    background: url("../../assets/icons/coatings_bronze.svg");
}

.coating-gold {
    background: url("../../assets/icons/coatings_gold.svg");
}

.coating-green {
    background: url("../../assets/icons/coatings_green.svg");
}

.coating-red {
    background: url("../../assets/icons/coatings_red.svg");
}

.coating-silver {
    background: url("../../assets/icons/coatings_silver.svg");
}

.coating-white {
    background: url("../../assets/icons/coatings_white.svg");
}

.tint-ass-blue {
    background: url("../../assets/icons/tints_adaptiveSunsolid_blue.svg");
}
.tint-ass-brown {
    background: url("../../assets/icons/tints_adaptiveSunsolid_brown.svg");
}

.rotation-3d-black {
    background: url("../../assets/icons/3d_rotation_black.svg");
}

.tint-asg-brown {
    border-radius: 50px;
    width: 44px;
height: 44px;
box-shadow: 0.5px 0.5px 2px 0 rgba(0, 0, 0, 0.5);
background-image: linear-gradient(to top, #e1dfdb 50%, #523822 50%);
}

.tint-uc-brown {
    border-radius: 50px;
    width: 44px;
height: 44px;
box-shadow: 0.5px 0.5px 2px 0 rgba(0, 0, 0, 0.5);
background-image: linear-gradient(to top, #e1dfdb 50%, #523822 50%);
}

.combined-shape {
    width: 15.5px;
    height: 18.5px;
    background-image: linear-gradient(234deg, #ffffff 86%, rgba(255, 255, 255, 0) 46%);
    }

.tint-ass-grey {
    background: url("../../assets/icons/tints_adaptiveSunsolid_grey.svg");
}

.tint-ass-pioneer {
    background: url("../../assets/icons/tints_adaptiveSunsolid_pioneer.svg");
}

.tint-pf-blue {
    background: url("../../assets/icons/tints_photofusion_blue.svg");
}

.tint-pf-brown {
    background: url("../../assets/icons/tints_photofusion_brown.svg");
}

.tint-pf-extragrey {
    background: url("../../assets/icons/tints_photofusion_extragrey.svg");
}

.tint-pf-grey {
    background: url("../../assets/icons/tints_photofusion_grey.svg");
}

.tint-pf-pioneer {
    background: url("../../assets/icons/tints_photofusion_pioneer.svg");
}

    
.tint-icon-style {
    width: 44px !important;
    height: 44px !important;
    box-shadow: 0.5px 0.5px 2px 0 rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    background-color: rgb(238, 238, 238) !important;
    margin-left: 0px !important;
}